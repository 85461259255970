* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none;
  letter-spacing: 0.2px;
  font-family: 'Be Vietnam Pro', sans-serif;
}

/*Turn on custom 8px wide scrollbar*/
::-webkit-scrollbar {
  width: 10px; /* 1px wider than Lion. */
  /* This is more usable for users trying to click it. */
  /*-webkit-border-radius: 100px;*/
}
::-webkit-scrollbar:horizontal {
  height: 10px;
}
/* hover effect for both scrollbar area, and scrollbar 'thumb' */
/*::-webkit-scrollbar:hover {*/
/*    background-color: rgba(0, 0, 0, 0.09);*/
/*}*/

/* The scrollbar 'thumb' ...that marque oval shape in a scrollbar */
::-webkit-scrollbar-thumb:vertical {
  /* This is the EXACT color of Mac OS scrollbars.
     Yes, I pulled out digital color meter */
  background: #E5E5E5;
  -webkit-border-radius: 100px;
  background-clip: padding-box;
  border: 2px solid rgba(0, 0, 0, 0);
  min-height: 10px; /*Prevent it from getting too small */
}

::-webkit-scrollbar-thumb:horizontal {
  /* This is the EXACT color of Mac OS scrollbars.
     Yes, I pulled out digital color meter */
  background: #E5E5E5;
  -webkit-border-radius: 100px;
  background-clip: padding-box;
  border: 2px solid rgba(0, 0, 0, 0);
  min-width: 5px; /*Prevent it from getting too small */
}

button, input, p {
  color: #4E516A;
}

button {
  border: solid;
}

input:focus {
  outline: none;
}

body {
  overflow: hidden;
  height: 100%;
  margin: 0;
  line-height: 1.4;
  font-family: 'Be Vietnam Pro', sans-serif;
  font-weight: 300;
  color: #4E516A;
}

a {
  color: #333;
  text-decoration: none;
}


/* TODO: MOVE TO STYLES.SCSS AND ASSIGN VALUES TO VARIABLES */

.focusable-text {
  cursor: pointer;
  border: 1px solid transparent;
  padding: 5px;
  margin-left: -5px;
}

.focusable-input:focus,
.focusable-input:active,
.focusable-text:focus,
.focusable-text:hover {
  background-color: #DEEBFF;
}

.hoverable-text:hover,
.hoverable-text:focus {
  color: #4A90E2;
  cursor: pointer;
}

/* TODO: CHANGE TO USE LIGHTEN/DARKEN SASS FUNCTIONS */
.hoverable-button {
  opacity: 0.9;
  transition: all ease-in-out 300ms;
}

.hoverable-button:focus,
.hoverable-button:active,
.hoverable-button:hover {
  opacity: 1;
  transform: translate(0px, -5px);
}

.create-availability-button {
  background: linear-gradient(#45C6A9, #4BDDBC);
}

.create-event-button {
  background: linear-gradient(#4A90E2, #52A2FF);
}

.disabled-icon {
  cursor: pointer;
  opacity: 0.3;
  /* transition: opacity 0.3s; */
  color: #4E516A;
}

.clickable-icon {
  cursor: pointer;
  opacity: 0.3;
  transition: opacity 0.3s;
  color: #4E516A;
}

.clickable-icon:hover {
  opacity: 0.9;
}

.side-menu-div {
  padding-left: -10px;
}

.side-menu-div:hover {
  padding-left: 15px;
}

.rbc-header {
  border-left: 1px solid transparent;
}

.rbc-day-bg+.rbc-day-bg {
  border-left: 1px solid transparent;
}

.rbc-day-slot .rbc-events-container {
  margin-right: 10px;
}

.rbc-day-slot .rbc-event-content {
  font-weight: 600;
  word-wrap: normal;
}

.weekly-toolbar-header {
  margin-left: 40px;
  display: flex;
  flex-direction: row;
}

.weekly-toolbar-start-to-end-of-week-header {
  border-width: 0px;
  font-size: 20px;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.emoji-mart {
  border: none;
}

.text-link {
  text-decoration: underline;
  cursor: pointer;
}

.text-link:hover {
  color: #4A90E2;
}

.text-link-top-bar {
  text-decoration: underline;
  cursor: pointer;
}

.text-link-top-bar:hover {
  color: orange;
  transition: 0.2s;
}
